export const SYSTEM = {
  MARKS: 'marks',
  SKATING: 'skating',
  AJS: 'ajs',
  AJS_FORMATION: 'ajs_formation',
  AJS_SHOWDANCE: 'ajs_showdance',
  S3_6: 's3-6',
  SIMPLEAJS: 'simpleajs'
} as const;

export default SYSTEM;
type t = typeof SYSTEM;
export type SystemType = t[keyof t];
