import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../app/store';
export interface HomeState {
  darkMode: boolean;
}

const initialState: HomeState = {
  darkMode: false
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    changeDarkMode(state, action: PayloadAction<boolean>) {
      // const { darkState } = action.payload
      state.darkMode = action.payload;
    }
  }
});

// Export actions
export const { changeDarkMode } = homeSlice.actions;

//selectors
export const selectDarkMode = (state: RootState) => {
  return state.home.darkMode;
};

// Export reducer
export default homeSlice.reducer;
