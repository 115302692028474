

export const DEVICE = {
  JUDGE: "judge",
  MONITOR: "monitor",
  CHAIRPERSON: "chairperson",
  LED: "led",
  MC: "mc",
  DJ: "dj",
} as const;

export default DEVICE;
type t = typeof DEVICE;
export type DeviceType =  t[keyof t];