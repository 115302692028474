

export const SOCKET = {
  ADJUDICATOR_LOG_IN: "adjudicatorLogIn",
  ADJUDICATOR_SHEET: "adjudicatorSheet",
  ACK_ADJUDICATOR_SHEET: "ackAdjudicatorSheet",
  ADJUDICATOR_ROUND_RESULTS: "adjudicatorRoundResults",

} as const;

export default SOCKET;
type t = typeof SOCKET;
export type SocketType =  t[keyof t];