import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../app/store';
import { v4 as uuidv4 } from 'uuid';
import { DEVICE, DeviceType } from '../constants/deviceType';

export interface SettingsState {
  socketConnected: boolean;
  socketAddress: string;
  deviceType: DeviceType;
  deviceId: number;
  uuid: string;
  visualEffects: boolean;
}

const initialState: SettingsState = {
  socketConnected: false,
  socketAddress: '',
  deviceType: DEVICE.JUDGE,
  deviceId: 0,
  visualEffects: true,
  uuid: uuidv4()
};

export const clearAll = createAsyncThunk(
  'settings/clear',
  async function (_payload, thunkAPI) {
    thunkAPI.dispatch({ type: 'CLEAR_ALL' });
    // console.log('cleared state');
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeSocketConnected(state, action: PayloadAction<boolean>) {
      state.socketConnected = action.payload;
    },
    changeSocketAddress(state, action: PayloadAction<string>) {
      state.socketAddress = action.payload;
    },
    changeDeviceType(state, action: PayloadAction<DeviceType>) {
      state.deviceType = action.payload;
    },
    changeDeviceId(state, action: PayloadAction<number>) {
      state.deviceId = action.payload;
    },
    changeVisualEffects(state, action: PayloadAction<boolean>) {
      state.visualEffects = action.payload;
    }
  }
});

// Export actions
export const {
  changeSocketAddress,
  changeDeviceType,
  changeDeviceId,
  changeSocketConnected,
  changeVisualEffects
} = settingsSlice.actions;

//selectors
export const selectSocketConnected = (state: RootState) => {
  // console.log(state);
  return state.settings.socketConnected;
};
export const selectSocketAddress = (state: RootState) => {
  return state.settings.socketAddress;
};
export const selectDeviceType = (state: RootState) => {
  return state.settings.deviceType;
};
export const selectDeviceId = (state: RootState) => {
  return state.settings.deviceId;
};
export const selectDeviceUuid = (state: RootState) => {
  return state.settings.uuid;
};
export const selectVisualEffects = (state: RootState) => {
  return state.settings.visualEffects;
};
// Export reducer
export default settingsSlice.reducer;
