

export const TASK = {
  NEW: "new",
  IN_PROGRESS: "in_progress",
  DONE: "done",
} as const;

export default TASK;
type t = typeof TASK;
export type TaskType =  t[keyof t];