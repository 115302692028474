import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import FsBackend from 'i18next-fs-backend';
import en from './locale/en/translation.json';
import pl from './locale/en/translation.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(FsBackend)
  .init({
    backend: {
      expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
      loadPath: './locale/{{lng}}/{{ns}}.json',
      addPath: './locale/{{lng}}/{{ns}}.missing.json'
    },
    resources: {
      en: {
        translation: en
      },
      pl: {
        translation: pl
      }
    },
    debug: true,
    namespace: 'translation',
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    saveMissing: true,
    saveMissingTo: 'all',
    fallbackLng: false, // set to false when generating translation files locally

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
