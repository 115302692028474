import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// import "./index.css";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { store } from './app/store';
import { Provider } from 'react-redux';
import 'inobounce';

import './i18n';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { SocketProvider } from './app/SocketContext';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const persistor = persistStore(store);
root.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <SocketProvider store={store}>
            <App />
          </SocketProvider>
        </Provider>
      </PersistGate>
    </Suspense>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
